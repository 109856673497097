<script>
import AuthService from '@/service/auth.service';
import { UserRoleType } from '@/utils/enums/UserRoleType';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';

export default {
  name: 'profile',

  components: {
    FormAvatarUpload: () => import('@/components/FormAvatarUpload'),
  },

  data() {
    return {
      loading: false,
      userRoleType: UserRoleType,
      form: {},
    };
  },

  computed: {
    ...mapState('auth', {
      currentUser: (state) => state.user,
    }),
  },

  created() {
    this.form = cloneDeep(this.currentUser);
  },

  methods: {
    async save() {
      this.loading = true;
      try {
        const form = cloneDeep(this.form);
        form.avatarId = this.$refs?.avatar?.avatarId;
        await AuthService.updateMe(form);
        this.$store.dispatch('auth/loadMe');
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);

        this.$toastr.success(`Copiado ${text}`);
      } catch (error) {
        this.$toastr.error('Erro ao copiar');
      }
    },
  },
};
</script>

<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title>Meus dados</v-card-title>
        <v-card-text class="pt-5">
          <v-container>
            <v-row dense>
              <v-col lg="8" md="8" sm="12">
                <v-row v-if="form.tenant">
                  <v-col cols="12" lg="8" md="8" sm="12">
                    <span>Organização</span>
                    <h1>{{ form.tenant.name }}</h1>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" sm="12">
                    <span>SID #{{ form.tenant.sid }}</span>
                    <v-btn icon tile small @click="copyText(form.tenant.sid)">
                      <v-icon small> mdi-content-copy </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>Email</span>
                    <h2>{{ form.email }}</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="8" md="8" sm="12">
                    <v-text-field
                      v-model="form.name"
                      label="Nome"
                      color="white"
                      hide-details="auto"
                      outlined
                      persistent-placeholder
                      dense
                    />
                  </v-col>
                  <v-col cols="12" lg="4" md="4" sm="12">
                    <v-text-field
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                      label="CPF"
                      color="white"
                      hide-details="auto"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row> </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="12" class="text-center justify-center">
                <FormAvatarUpload ref="avatar" v-model="form.avatar" :size="200" />
              </v-col>
            </v-row>
            <v-row justify="start" class="pt-4" dense>
              <v-btn class="ml-1" color="success" type="submit" :loading="loading" :disabled="loading">Salvar</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>
